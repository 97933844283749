<script>
import StraNavigation from './layout/StraNavigation.vue';

export default {
    name: 'StraLayout',

    components: { StraNavigation },

    data: () => ({
        drawer: true,
    }),

    methods: {
        invertColors() {
            this.$vuetify.theme.dark = ! this.$vuetify.theme.dark;
        },
    }
};

</script>

<template>
    <div class="full-height">
        <v-app-bar
            app
            clipped-left
            flat
            class="primary"
        >
            <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
            <img class="toolbar__logo" src="@/assets/images/logo_white.svg">
            <v-spacer />
            <v-btn icon dark @click="invertColors">
                <v-icon>
                    mdi-invert-colors
                </v-icon>
            </v-btn>
        </v-app-bar>

        <v-navigation-drawer v-model="drawer" clipped app>
            <stra-navigation
                ref="straNavigation"
                @show-navigation="showNavigation = true"
            />
        </v-navigation-drawer>

        <v-main class="full-height">
            <v-container fluid class="full-height">
                <router-view />
            </v-container>
        </v-main>
    </div>
</template>

<style lang="scss">
    .v-app-bar {
        .toolbar {
            &__logo {
                max-height: 75%;
            }
        }
    }

    .full-height {
        height: 100%;
    }

</style>
