import { ModelApiService } from "./BaseApiService";

export default class KampenApiService extends ModelApiService {
    /**
     * @param {string} group
     * UserApiService Constructor
     */
    constructor(group = null) {
        if (! group) {
            super("portaal/kampen");
        } else {
            super("kampen");
            this.group = group;
        }
    }

    /**
     * @param {string} id
     * @returns {string}
     */
    getUrl(id = "") {
        let url = super.getUrl(id);
        return this.group
            ? `${url}/${this.group}`
            : url
        ;
    }
}
