import StraLayout from '@/components/stra/StraLayout';
import KampOverview from '@/components/stra/modules/KampOverview/KampOverview';
import PersoonEdit from '@/components/stra/modules/PersoonEdit/PersoonEdit';
import LoginGuard from './LoginGuard';

const routes = [
    {
        path: '/',
        component: StraLayout,
        children: [
            {
                name: 'kampenOverview',
                path: '/kampen/:id',
                component: KampOverview,
                props: true,
            },
            {
                path: '/persoon/:id',
                component: PersoonEdit,
            },
        ],
        beforeEnter: (to, from, next) => {
            if (! LoginGuard()) {
                next("/authorization");
            } else {
                next();
            }
        }
    },
];

export default routes;
