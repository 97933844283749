<script>

import {usePersonStore} from "@/stores/PersonStore";
import {mapStores} from "pinia";

export default {
    name: 'PersoonEditPersoon',

    data() {
        return {
            dateDialog: false,
        };
    },

    computed: {
        ...mapStores(usePersonStore),
    }
};
</script>

<template>
    <div>
        <div class="card-header">
            <div style="display: flex">
                <v-icon>mdi-account</v-icon>
                <v-card-title>Persoon</v-card-title>
            </div>
            <v-divider />
        </div>
        <div v-if="$slots.alert" class="alert-container">
            <slot name="alert" />
        </div>
        <div class="edit-container">
            <div>
                <v-text-field
                    v-model="personStore.voornaam"
                    label="Voornaam"
                />
                <v-text-field
                    v-model="personStore.tussenvoegsels"
                    label="Tussenvoegsel"
                />
                <v-text-field
                    v-model="personStore.achternaam"
                    label="Achternaam"
                />
            </div>
            <div>
                <div class="date-input">
                    <v-text-field
                        v-model="personStore.geboortedatum"
                        label="Geboortedatum"
                    />

                    <v-dialog
                        ref="dateDialog"
                        v-model="dateDialog"
                        :return-value.sync="personStore.geboortedatum"
                        persistent
                        width="290px"
                    >
                        <template #activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                                <v-icon>
                                    mdi-calendar
                                </v-icon>
                            </v-btn>
                        </template>
                        <v-date-picker v-model="personStore.geboortedatum">
                            <v-spacer />
                            <v-btn
                                text
                                color="primary"
                                @click="dateDialog = false"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.dateDialog.save(personStore.geboortedatum)"
                            >
                                OK
                            </v-btn>
                        </v-date-picker>
                    </v-dialog>
                </div>

                <v-select
                    v-model="personStore.geslacht"
                    label="Geslacht"
                    :items="[
                        { 'value': 'm', 'label': 'Man' },
                        { 'value': 'v', 'label': 'Vrouw' }
                    ]"
                    item-value="value"
                    item-text="label"
                />

                <v-select
                    v-model="personStore.actief"
                    label="Status"
                    :items="[
                        { 'value': true, 'label': 'Actief' },
                        { 'value': false, 'label': 'Inactief' }
                    ]"
                    item-value="value"
                    item-text="label"
                />
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .card-header {
        height: 79px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 12px;
    }

    .edit-container {
        @media screen and (min-width: 769px) {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            gap: 25px 50px;

            > div {
                width: calc(50% - 25px);
            }
        }
    }

    .date-input {
        display: flex;
        align-items: center;
    }
</style>
