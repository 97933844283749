<script>

export default {
    name: "LoginLayout",

    computed: {
        wrapperClass() {
            return this.$route.fullPath === '/authorization'
                ? 'login__wrapper'
                : 'login__wrapper login__wrapper--darker'
            ;
        }
    }
};
</script>

<template>
    <div :class="wrapperClass">
        <div class="login__form">
            <img class="login__logo" src="@/assets/images/logo_white.svg">
            <router-view />
        </div>
    </div>
</template>

<style lang="scss">

    .theme--light {
        --login-background: url('@/assets/images/login.jpg');
    }
    .theme--dark {
        --login-background: url('@/assets/images/login_dark.jpg');
    }

    .login {
        &__wrapper {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: {
                image: var(--login-background);
                size: cover;
                position: center center;
            }
            :not(&.login__wrapper--darker) {
                &:before {
                    background: rgba(0,0,0,0.3);
                }
            }
            .login__wrapper--darker {
                &:before {
                    background: rgba(0,0,0,0.5);
                }
            }
            &:before {
                transition-duration: 1s;
                content: '';
                width: 100%;
                height: 100%;
                position: absolute;
                background: rgba(0,0,0,0.6);
                z-index: 1;
            }
        }

        &__logo {
            display: block;
            width: 210px;
            margin: auto;
        }
        &__form {
            margin: auto;
            width: 280px;
            z-index: 10;
        }
    }
</style>
