import {defineStore} from "pinia";

export const useAccountStore = defineStore('account', {
    state: () => ({
        id:                null,
        gebruikers_naam:   null,
        email:             null,
        email_verified_at: null,
        uses_2fa:          null,
        created_at:        null,
        updated_at:        null,
        deleted_at:        null,
    }),
    actions: {
        setPerson(account) {
            if (! account) {
                return;
            }
            for (let key of Object.keys(account)) {
                if (this[key] !== undefined) {
                    this[key] = account[key];
                }
            }
        }
    },
});
