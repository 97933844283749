<script>

import axios from 'axios';
import { required, minLength, sameAs } from 'vuelidate/lib/validators';

export default {
    name: 'ResetPassword',

    data() {
        return {
            formSuccess: false,
            password:    '',
            passwordConfirm: '',
            pending:     false,
            username:    '',
        };
    },

    validations: {
        username:        { required },
        password:        { required, minLength: minLength(6) },
        passwordConfirm: { required, minLength: minLength(6), sameAs: sameAs('password') },
    },

    methods: {
        async handleSubmit() {
            console.log("Submit!");
            this.$v.$touch();

            if (this.$v.$invalid) return;

            this.pending = true;
            const params = {
                reset_token:      this.$route.query.token,
                gebruikers_naam:  this.username,
                wachtwoord:       this.password,
                wachtwoord_check: this.passwordConfirm,
            };
            try {
                await axios.post('/auth/password/reset', params);
                this.formSuccess = true;
            } catch (e) {
                console.error(e);
            } finally {
                this.pending = false;
            }
        }
    }
};

</script>

<template>
    <div class="form__wrapper">
        <div v-show="! pending">
            <div v-if="! formSuccess">
                <div class="forgot-text">
                    Vul hieronder twee keer je nieuwe wachtwoord in.
                </div>
                <form>
                    <v-text-field
                        v-model="username"
                        label="Gebruikersnaam"
                        :validations="$v.username"
                        dark
                        @keypress.enter="handleSubmit"
                    />
                    <v-text-field
                        v-model="password"
                        type="password"
                        label="Wachtwoord"
                        :validations="$v.password"
                        dark
                        @keypress.enter="handleSubmit"
                    />
                    <v-text-field
                        v-model="passwordConfirm"
                        type="password"
                        :validations="$v.passwordConfirm"
                        label="Wachtwoord bevestigen"
                        dark
                        @keypress.enter="handleSubmit"
                    />
                </form>
            </div>
            <div v-else class="forgot-text">
                Je wachtwoord is ingesteld. Klik op "login" om in te loggen met je nieuwe wachtwoord.
            </div>
            <v-btn
                class="form__button"
                to="/login"
                text
                dark
            >
                {{ formSuccess ? 'login' : 'Terug' }}
            </v-btn>
        </div>
        <div v-show="pending" style="text-align: center;">
            <v-progress-circular indeterminate color="primary" :size="50" />
        </div>
    </div>
</template>

<style lang="scss" scoped>

    .form {
        &__wrapper {
            display: flex;
            height: 350px;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            text-align: center;

            ::v-deep .form__button.v-btn.theme--dark,
            ::v-deep .form__button.v-btn.theme--dark:before {
                background: none !important;
                background-color: transparent !important;

                &:hover {
                    text-decoration: none;
                }
            }
        }
        &__button {
            width: 280px;
            margin: 0;
        }
    }

    .forgot-text {
        margin-bottom: 10px;
    }

</style>
