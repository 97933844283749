<script>

export default {

    name: "StraNavigationItem",

    props: {
        subGroup: {
            type: Boolean,
            default: false,
        },

        value: {
            type: Object,
            required: true,
        }
    },

    data() {
        return {
            activeItem: '',
        };
    },

    computed: {
        hasChildren() {
            for (let item of this.value.items) {
                if (item.items) {
                    return true;
                }
            }
            return false;
        }
    },

    methods: {
        setActive(name) {
            this.activeItem = this.activeItem !== name
                ? name
                : ''
            ;

            this.$emit('show-navigation');
        },

        closeSubmenus() {
            this.activeItem = '';
        }
    }

};
</script>

<template>
    <v-list-group
        :prepend-icon="value.icon"
        :sub-group="subGroup"
        :class="{ 'ultra-dense': subGroup }"
        no-action
    >
        <template #activator>
            <v-list-item-title>{{ value.name }}</v-list-item-title>
        </template>

        <template v-for="item of value.items">
            <v-list-item
                v-if="! item.items"
                :key="item.key"
                link
                @click="$router.push(item.route)"
            >
                <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item>

            <stra-navigation-item
                v-else
                :key="item.key"
                :value="item"
                :sub-group="true"
            >
                <stra-navigation-item :value="item" />
            </stra-navigation-item>
        </template>
    </v-list-group>
</template>

<style lang="scss" scoped>

    .v-list--dense .v-list-item, .v-list-item--dense {
        min-height: 25px;
    }

    .ultra-dense::v-deep > .v-list-item {
        min-height: 25px !important;

        .v-list-item__title, .v-list-item__icon {
            margin: 0;
        }
    }


    .stra-submenu {
        &__wrapper {
            height:100%;
            overflow: auto;
            background: rgba(0,0,0,0.05);
            padding: 0 35px;
            &--hidden {
                .stra-submenu__item {
                    display: none;
                }
            }
            &:not(&.stra-submenu__wrapper--hidden) {

                .stra-submenu__item {
                    padding: 3px 0;
                    list-style: none;
                }

                .stra-submenu__list {
                    margin: 0 -35px;
                    background: rgba(0,0,0,0.05);
                    .stra-submenu__item {
                        padding: 3px 25px !important;
                    }
                }
            }
        }
    }

</style>
