/**
 *
 * @returns {boolean}
 */
export default function() {

    const token = localStorage.getItem('token');
    if (! token) {
        return false;
    }
    const jwt     = token.split(" ")[1];
    const payload = jwt.split(".")[1];
    const json    = JSON.parse(atob(payload));
    const now     = new Date();

    return ((json.exp * 1000) > now.getTime());
}
