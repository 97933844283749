import axios from 'axios';

export class BaseApiService {

    /**
     * @param {string} resource
     */
    constructor(resource) {
        if (!resource) throw new Error("Resource is not provided");
        this.resource = resource;
    }

    /**
     * @param {string} id
     * @returns {string}
     */
    getUrl(id = "") {
        return `${this.resource}/${id}`;
    }

    /**
     * @returns {void}
     */
    updateAuthorizationHeader() {
        axios.defaults.headers.common['Authorization'] = localStorage.getItem('token');
    }
}

export class ReadOnlyApiService extends BaseApiService {
    /**
     * @param {string} resource
     */
    constructor(resource) {
        super(resource);
    }
    /**
     *
     * @param {object} config
     * @returns {object}
     */
    async fetch(config = {}) {
        const response = await axios.get(this.getUrl(), config);
        return await response.data;
    }

    /**
     * @param {number} id
     * @returns {object}
     */
    async get(id) {
        if (! id) {
            throw Error("Id is not provided");
        }
        const response = await axios.get(this.getUrl(id));
        return response.data;
    }
}

export class ModelApiService extends ReadOnlyApiService {
    /**
     * @param {string} resource
     */
    constructor(resource) {
        super(resource);
    }

    /**
     * @param {object} data
     * @returns {object}
     */
    async post(data = {}) {
        const response = await axios.post(this.getUrl(), data);
        if (response.data.error) {
            throw response.data.errors;
        }
        const { id }   = response.data.data.id;
        return id;
    }

    /**
     * @param {number} id
     * @param {object} data
     * @returns {object}
     */
    async put(id, data = {}) {
        if (! id) {
            throw Error("Id is not provided");
        }
        const response = await axios.put(this.getUrl(id), data);
        if (response.data.error) {
            throw response.data.errors;
        }
        return response.data.data;
    }

    /**
     *
     * @param {number} id
     * @returns {object}
     */
    async delete(id) {
        if (! id) {
            throw Error("Id is not provided");
        }
        const response = await axios.delete(this.getUrl(id));
        if (response.data.error) {
            throw response.data.errors;
        }
        return true;
    }
}
