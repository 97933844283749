
import { $api } from "@/services/api";

/**
 * @param {*} store
 * @returns {void}
 */
export default function(store) {
    try {
        store.$api = $api;
    } catch (e) {
        console.error(e);
    }
}
