<script>
import PersoonEditAccount from './components/PersoonEditAccount.vue';
import PersoonEditAdres from './components/PersoonEditAdres.vue';
import PersoonEditHistorie from './components/PersoonEditHistorie.vue';
import PersoonEditNotities from './components/PersoonEditNotities.vue';
import PersoonEditPersoon from './components/PersoonEditPersoon.vue';
import PersoonEditRelaties from './components/PersoonEditRelaties.vue';
import PersoonEditSidebar from './components/PersoonEditSidebar.vue';
import {usePersonStore} from "@/stores/PersonStore";
import {useAccountStore} from "@/stores/AccountStore";

import {mapStores} from "pinia";
import {cloneDeep} from "lodash";
import PersonenApiService from "@/services/PersonenApiService";
import {useNotificationStore} from "@/stores/NotificationStore";

export default {
    name: "PersoonEdit",
    components: { PersoonEditSidebar, PersoonEditPersoon, PersoonEditAdres, PersoonEditHistorie, PersoonEditAccount, PersoonEditNotities, PersoonEditRelaties },

    data() {
        return {
            tab:           'account',
            storedPersoon: null,
            pending:       true,
        };
    },

    computed: {
        ...mapStores(usePersonStore, useAccountStore, useNotificationStore),

        fullName() {
            if (this.pending) return '';
            return this.storedPersoon.tussenvoegsels
                ? `${this.storedPersoon.voornaam} ${this.storedPersoon.tussenvoegsels} ${this.storedPersoon.achternaam}`
                : `${this.storedPersoon.voornaam} ${this.storedPersoon.achternaam}`
            ;
        },

        changedFields() {
            if (! this.personStore || ! this.storedPersoon) return [];
            return this.getDifferentFields(this.storedPersoon, this.personStore.$state);
        }
    },

    mounted() {
        this.getPersoon();
    },

    methods: {
        async getPersoon() {
            let persoonId      = this.$route.params.id;
            let response       = await this.$api.personen.get(persoonId);
            this.storedPersoon = cloneDeep(response);
            this.personStore.setPerson(cloneDeep(response));
            this.accountStore.setPerson(cloneDeep(response.account));
            this.pending       = false;
        },

        getDifferentFields(object1, object2) {
            const ignoredFields = ['historie'];
            let differences = [];
            const objKeys1  = Object.keys(object1);

            for (let key of objKeys1) {
                if (ignoredFields.indexOf(key) > -1) continue;

                const value1    = object1[key];
                const value2    = object2[key];
                const isObjects = this.isObject(value1) && this.isObject(value2);

                if (! isObjects && value1 !== value2 && value2 !== undefined) {
                    differences.push(key);
                }
                if (isObjects && this.getDifferentFields(value1, value2).length > 0) {
                    differences.push({[key]: this.getDifferentFields(value1, value2)});
                }
            }
            return differences;
        },

        isObject(value) {
            return typeof value === 'object' &&
                ! Array.isArray(value) &&
                value !== null
            ;
        },

        async save() {
            this.pending = true;
            try {
                let service = new PersonenApiService();
                await service.put(this.personStore.id, this.changedFields);
            } catch(e) {
                this.notificationStore.setNotification('Er is iets misgegaan', 'error');
                this.notificationStore.active = true;

                //eslint-disable-next-line no-console
                console.error(e);
            }
            this.pending = false;
        }
    }
};
</script>

<template>
    <div class="full-height">
        <v-card
            v-if="personStore.id"
            :loading="pending"
            :disabled="pending"
        >
            <div class="persoon-edit__wrapper">
                <persoon-edit-sidebar
                    v-model="tab"
                    :name="fullName"
                    subtitle="_deelnemer kamp 4_"
                    class="persoon-edit__sidebar"
                />
                <v-card-text>
                    <v-tabs-items v-model="tab" :transition="false">
                        <v-tab-item :transition="false" :reverse-transition="false">
                            <persoon-edit-persoon>
                                <template #alert>
                                    <v-alert v-if="! storedPersoon.actief" border="left" type="warning">
                                        Let op! Deze persoon is inactief!
                                    </v-alert>
                                </template>
                            </persoon-edit-persoon>
                        </v-tab-item>
                        <v-tab-item :transition="false" :reverse-transition="false">
                            <persoon-edit-account>
                                <template #alert>
                                    <v-alert v-if="! storedPersoon.actief" border="left" type="warning">
                                        Let op! Deze persoon is inactief!
                                    </v-alert>
                                </template>
                            </persoon-edit-account>
                        </v-tab-item>
                        <v-tab-item :transition="false" :reverse-transition="false">
                            <persoon-edit-adres>
                                <template #alert>
                                    <v-alert v-if="! storedPersoon.actief" border="left" type="warning">
                                        Let op! Deze persoon is inactief!
                                    </v-alert>
                                </template>
                            </persoon-edit-adres>
                        </v-tab-item>
                        <v-tab-item :transition="false" :reverse-transition="false">
                            <persoon-edit-historie>
                                <template #alert>
                                    <v-alert v-if="! storedPersoon.actief" border="left" type="warning">
                                        Let op! Deze persoon is inactief!
                                    </v-alert>
                                </template>
                            </persoon-edit-historie>
                        </v-tab-item>
                        <v-tab-item :transition="false" :reverse-transition="false">
                            <persoon-edit-relaties>
                                <!-- <template #alert>
                                    <v-alert v-if="! persoon.actief" border="left" type="warning">
                                        Let op! Deze persoon is inactief!
                                    </v-alert>
                                </template>-->
                            </persoon-edit-relaties>
                        </v-tab-item>
                        <v-tab-item :transition="false" :reverse-transition="false">
                            <persoon-edit-notities>
                                <!-- <template #alert>
                                    <v-alert v-if="! persoon.actief" border="left" type="warning">
                                        Let op! Deze persoon is inactief!
                                    </v-alert>
                                </template>-->
                            </persoon-edit-notities>
                        </v-tab-item>
                    </v-tabs-items>
                </v-card-text>
            </div>
            <v-divider />
            <v-card-actions class="persoon-edit__footer">
                <v-btn text color="primary">
                    Annuleren
                </v-btn>
                <v-btn color="primary" :disabled="pending" @click="save">
                    Opslaan
                </v-btn>
            </v-card-actions>
        </v-card>
        <div v-else class="stra-full-page-spinner">
            <v-progress-circular indeterminate color="primary" />
        </div>
    </div>
</template>

<style lang="scss" scoped>

    .v-card__text {
        @media screen and (min-width: 769px) {
            width: calc(100% - 256px) !important;
        }
    }

    .persoon-edit {
        &__wrapper {
            display: flex;
            @media screen and (max-width: 768px) {
                flex-direction: column;;
            }
        }
        &__sidebar {
            height: 100%;
            overflow:  auto;
        }
        &__footer {
            justify-content: flex-end;
        }
    }
    .editor {
        &__form {
            padding: 20px;
            overflow: auto;
            height: calc(100% - 110px);
        }

        &__header {
            height: 110px;
            border-width: 0 0 1px 0;
            border-color: #dddddd;
            border-style: solid;
            display: flex;
            align-items: center;
            font-size: 22px;
            padding: 20px;
        }
    }


    .full-height {
        height: 100%;
    }
</style>
