export default {
    methods: {
        getError(validator) {
            if (! this.$v[validator].$dirty) return [];
            if (this.$v[validator].required === false) return "Dit veld is verplicht!";
            if (this.$v[validator].minLength === false) return `Dit veld moet minimaal ${this.$v[validator].$params.minLength.min} tekens lang zijn.`;
            if (this.$v[validator].maxLength === false) return `Dit veld mag maximaal ${this.$v[validator].$params.minLength.min} tekens lang zijn.`;
            if (this.$v[validator].sameAs === false) return `Dit waarde van dit veld komt niet overeen`;

            // There are errors but the error is not specified above, so throw an general error
            if (this.$v[validator].$anyError) return "Geen geldige waarde";

            // No errors found, return null
            return null;
        }
    }
};
