<script>

import { required } from 'vuelidate/lib/validators';

export default {
    name: 'ForgotPassword',

    data() {
        return {
            formSuccess: false,
            username:    '',
            pending:     false,
        };
    },

    validations: {
        username: { required },
    },

    methods: {
        async handleSubmit() {
            this.$v.$touch();

            if (this.$v.$invalid) return;

            this.pending          = true;
            const params          = { gebruikers_naam: this.username };
            const passwordRequest = await this.$api.auth.forgot(params);

            if (passwordRequest) {
                this.formSuccess = true;
            }
            this.pending = false;
        }
    }
};

</script>

<template>
    <div class="form__wrapper">
        <div v-show="! pending">
            <div v-if="! formSuccess">
                <div class="forgot-text">
                    Vul hieronder je gebruikersnaam in. We sturen je een mail met instructies om je wachtwoord te resetten.
                </div>
                <form @submit.prevent="handleSubmit">
                    <v-text-field
                        v-model="username"
                        label="Gebruikersnaam"
                        :validations="$v.username"
                        dark
                        @keypress.enter="handleSubmit"
                    />
                    <v-btn
                        class="form__button"
                        elevation="0"
                        :disabled="$v.$invalid"
                        dark
                        text
                        @click="handleSubmit"
                    >
                        Versturen
                    </v-btn>
                </form>
            </div>
            <div v-else class="forgot-text">
                Bekijk je mailbox voor instructies om je wachtwoord te resetten.
            </div>
            <v-btn
                class="form__button"
                to="/login"
                text
                dark
            >
                Terug
            </v-btn>
        </div>
        <div v-show="pending" style="text-align: center;">
            <v-progress-circular indeterminate color="primary" :size="50" />
        </div>
    </div>
</template>

<style lang="scss" scoped>

    .form {
        &__wrapper {
            display: flex;
            height: 350px;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            text-align: center;

            ::v-deep .form__button.v-btn.theme--dark,
            ::v-deep .form__button.v-btn.theme--dark:before {
                background: none !important;
                background-color: transparent !important;

                &:hover {
                    text-decoration: none;
                }
            }
        }
        &__button {
            width: 280px;
            margin: 0;
        }
    }

    .forgot-text {
        color: #ffffff;
        margin-bottom: 25px;
    }

</style>
