import Vue from 'vue';
import VueRouter from 'vue-router';
import router from './router';
import App from './App.vue';
import store  from "./store/index";
import vuetify from '@/plugins/vuetify';

import "./mixins/Api";
import './axios';

// TODO: Check if can be removed
import moment from 'moment';
require('moment/locale/nl');

// Vuelidate
import Vuelidate from 'vuelidate';
Vue.use(Vuelidate);

// Pinia
import { createPinia, PiniaVuePlugin } from 'pinia';
Vue.use(PiniaVuePlugin);
const pinia = createPinia();

// TODO: Remove
import Vuex from 'vuex';
Vue.use(Vuex);

Vue.use(require('vue-moment'), {
    moment
});
Vue.config.productionTip = false;

Vue.use(VueRouter);

new Vue({
    router,
    store,
    vuetify,
    pinia,
    render: h => h(App),
}).$mount('#app');
