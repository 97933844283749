<script>

import { required, minLength } from 'vuelidate/lib/validators';
import FormFieldErrors from '../../mixins/FormFieldErrors';
// import StringInput from '../helpers/form/StringInput.vue';
// import PasswordInput from '../helpers/form/PasswordInput.vue';
import { UNAUTHENTICATED, TFA_INVALID_CODE } from '../../shared/ErrorCodes';

export default {

    name: 'LoginForm',

    // components: { StringInput, PasswordInput },

    mixins: [
        FormFieldErrors
    ],

    data() {
        return {
            email   :  '',
            password:  '',
            pending:    false,
            tfaCode:    '',
            tfaToken:   '',
            wrongLogin: false,
        };
    },

    validations: {
        email:    { $autoDirty: true, required },
        password: { $autoDirty: true, required, minLength: minLength(6) }
    },

    methods: {
        async handleLogin() {
            this.$v.$touch();

            if (this.$v.$invalid) return;

            this.pending    = true;
            this.wrongLogin = false;

            try {
                const params = {
                    email:      this.email,
                    wachtwoord: this.password
                };

                const response  = await this.$api.auth.login(params);

                if (response['2fa_token']) {

                    this.tfaToken = response['2fa_token'];
                    await this.$nextTick();
                    console.log("focus 2fa");
                    this.$refs.tfaInput.focus();
                    return;
                }

                await this.$nextTick();

                // TODO: Store me in vuex store
                await this.$api.auth.me();
                this.$router.push('/');
            } catch(e) {
                if (e.code && e.code === UNAUTHENTICATED) {
                    this.wrongLogin = true;
                }
                console.error(e);
            } finally {
                this.pending = false;
            }
        },

        async handleTfa() {
            // TODO: CHECK IF FORM IS VALID!!
            this.pending = true;
            try {
                const tfaValid  = await this.$api.auth.validateTwoFactorAuthentication(this.tfaToken, this.tfaCode);
                if (tfaValid) {
                    await this.$api.auth.me();
                    this.$router.push('/');
                } else {
                    this.pending = false;
                }
            } catch(e) {
                if (e.code && e.code === TFA_INVALID_CODE) {
                    this.$toast.error('De ingevoerde code komt niet overeen met de code in de authenticator app');
                    return;
                }
                this.$toast.error('Er is een onbekende fout opgetreden');
                console.error(e);
            } finally {
                this.pending = false;
            }
        }
    }
};

</script>

<template>
    <div class="form__wrapper">
        <form v-show="! pending && ! tfaToken" @submit.prevent="handleLogin">
            <v-text-field
                v-model="email"
                label="Gebruikersnaam"
                hide-details="auto"
                autofocus
                dark
                :error-messages="getError('email')"
                @input="$v.email.$touch"
            />
            <v-text-field
                v-model="password"
                label="wachtwoord"
                type="password"
                dark
                :error-messages="getError('password')"
                @keypress.enter="handleLogin"
                @input="$v.password.$touch"
            />
            <!-- <div v-if="wrongLogin" class="form__wrong-authorization">
                Verkeerde gebruikersnaam / wachtwoord combinatie.
            </div> -->
            <v-btn
                elevation="0"
                class="form__button"
                :disabled="$v.$invalid"
                dark
                @click="handleLogin"
            >
                Inloggen
            </v-btn>
            <v-btn
                elevation="0"
                class="form__button"
                dark
                to="/login/vergeten"
            >
                Wachtwoord vergeten
            </v-btn>
        </form>

        <div v-show="! pending && !! tfaToken" @submit.prevent="handleTfa">
            Vul de code van je authenticator app in:
            <v-otp-input
                ref="tfaInput"
                v-model="tfaCode"
                autofocus
                @keyup.enter="handleTfa"
            />
            <v-btn
                elevation="0"
                class="form__button"
                dark
                @click="handleTfa"
            >
                Verifiëren
            </v-btn>
        </div>
        <div v-show="pending" style="text-align: center;">
            <v-progress-circular indeterminate color="primary" :size="50" />
        </div>
    </div>
</template>

<style lang="scss" scoped>

    .form {
        &__wrapper {
            display: flex;
            height: 350px;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            ::v-deep .form__button.v-btn.theme--dark {
                background: none !important;
                background-color: transparent !important;

                &:hover {
                    text-decoration: none;
                }
            }
        }
        &__button {
            width: 280px;
            margin: 0;
            margin-bottom: 10px;
        }
        &__wrong-login {
            color: #ffffff;
            background: #ff5252;
            padding: 10px 15px;
            margin-bottom: 10px;
        }
    }

</style>
