<script>
export default {
    name: 'StraMultipleTabTable',

    props: {
        value: { type: Object,  default: () => ({}), },
        dense: { type: Boolean, default: false, }
    },

    data() {
        return {
            tab: null,
        };
    },

    methods: {
        formatData(table) {
            let result = JSON.parse(JSON.stringify(table));
            for (let column of table.columns) {
                if (column.formatValue) {
                    for (let row of result.data) {
                        row[column.value] = column.formatValue(row[column.value]);
                    }
                }
            }
            return result.data;
        }
    }
};
</script>

<template>
    <div>
        <v-tabs v-model="tab" background-color="primary" dark>
            <v-tab v-for="(table, key) of value" :key="key">
                {{ table.title }}
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
            <v-tab-item v-for="(table, key) in value" :key="key">
                <v-data-table
                    :headers="table.columns"
                    :items="formatData(table)"
                    :items-per-page="100"
                    :dense="dense"
                    hide-default-footer
                    multi-sort
                    class="elevation-1"
                    @click:row="table.clickAction"
                />
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<style scoped>

</style>
