<script>
import StraNavigationItem from './StraNavigationItem.vue';

export default {
    name: "StraNavigation",

    components: { StraNavigationItem },

    data() {
        return {
            activeSubmenu: '',
            kampen:        [],
            loading:       true,
            menuPath:      [],
        };
    },

    computed: {
        navigation() {
            let result = {};

            result.kampen = {
                icon: "$lckv-tent",
                items: [],
                name: "Kampen",
            };

            for (let kamp of this.kampen) {
                let item   = kamp;
                item.key   = kamp.id;
                item.name  = `Kamp ${kamp.nummer} - ${kamp.terrein.naam}`;
                item.route = `/kampen/${kamp.id}`;
                result.kampen.items.push(item);
            }

            result.weken = {
                icon: "mdi-calendar",
                items: this.weekItems,
                name:  "Weken",
            };

            result.terreinen = {
                icon: "$lckv-tree",
                items: this.terreinItems,
                name:  "Terreinen",
            };

            return result;
        },

        // We can do this in the navigation computed property but i do it in a separate roperty to keep it (sort of) clean
        weekItems() {
            let result = [];
            for (let kamp of this.kampen) {
                let weekArr = result.find((obj) => { return obj.week_nummer === kamp.week_nummer; });
                if (! weekArr) {
                    weekArr = {
                        week_nummer: kamp.week_nummer,
                        name:        `Week ${kamp.week_nummer}`,
                        items:        [],
                    };
                    result.push(weekArr);
                }

                let menuItem = {
                    key:   kamp.id,
                    name:  `Kamp ${kamp.nummer} - ${kamp.terrein.naam}`,
                    route: `/kampen/${kamp.id}`,
                };

                weekArr.items.push(menuItem);
            }
            return result;
        },

        // We can do this in the navigation computed property but i do it in a separate roperty to keep it (sort of) clean
        terreinItems() {
            let result = [];
            for (let kamp of this.kampen) {
                let terreinArr = result.find((obj) => { return obj.terrein_naam === kamp.terrein.naam; });
                if (! terreinArr) {
                    terreinArr = {
                        terrein_naam: kamp.terrein.naam,
                        name:         kamp.terrein.naam,
                        items:        [],
                    };
                    result.push(terreinArr);
                }

                let menuItem = {
                    key:   kamp.id,
                    name:  `Kamp ${kamp.nummer} - ${kamp.terrein.naam}`,
                    route: `/kampen/${kamp.id}`,
                };

                terreinArr.items.push(menuItem);
            }
            return result;
        }
    },

    mounted() {
        this.fetchKampen();
        this.loading = false;
    },

    methods: {
        async fetchKampen() {
            let kampenResponse = await this.$api.kampen.fetch();
            this.kampen = kampenResponse.data;
        },

        setActive(name) {
            this.activeSubmenu = this.activeSubmenu !== name
                ? name
                : ''
            ;

            this.$emit('show-navigation');
        },

        closeSubmenus() {
            this.activeSubmenu = '';
        }
    }
};

</script>

<template>
    <!-- <v-list class="stra-menu"> -->
    <v-list dense>
        <stra-navigation-item
            v-for="item of navigation"
            :key="item.name"
            :value="item"
            :open="activeSubmenu === item.name"
            :class="{ 'stra-menu__item-wrapper--open': activeSubmenu === item.name }"
            class="stra-menu__item-wrapper"
            @set-active="setActive(item.name)"
        />
        <div style="flex-grow: 1" />
    </v-list>
</template>

<style lang="scss" scoped>

    .stra-menu {
        display: flex;
        flex-direction: column;
        height: 100%;
        transition: .3s cubic-bezier(.4,0,.2,1);

        .stra-menu__item-wrapper {
            overflow: hidden;
        }

        .stra-menu__item-wrapper:not(.stra-menu__item-wrapper--open) {
            flex: 0 1 48px;
            min-height: 48px;
        }
        .stra-menu__item-wrapper--open {
            flex-grow: 1;
        }
    }
</style>
