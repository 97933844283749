<script>
import {usePersonStore} from "@/stores/PersonStore";
import {mapStores} from "pinia";

export default {
    name: "PersoonEditSidebar",

    props: {
        active:   {type: String, default: '' },
        name:     {type: String, default: '' },
        subtitle: {type: String, default: '' }
    },

    computed: {
        ...mapStores(usePersonStore),
        sidebarWidth() {
            return this.$vuetify.breakpoint.width > 768 ? 256 : "100%";
        },
        verticalTabs() {
            return this.$vuetify.breakpoint.width > 768;
        }
    }
};
</script>

<template>
    <v-navigation-drawer :width="sidebarWidth" permanent>
        <v-list-item style="padding: 0">
            <v-list-item-content style="padding: 0">
                <v-list-item-title class="sidebar__header">
                    <div class="header__content">
                        <v-avatar size="30%" class="sidebar__avatar">
                            <img src="https://www.placecage.com/640/640">
                        </v-avatar>
                        <div>
                            <div class="header__name">
                                {{ name }}
                            </div>
                            <div class="header__role">
                                {{ subtitle }}
                            </div>
                        </div>
                    </div>
                </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <v-tabs
            class="navigation"
            :value="active"
            :vertical="verticalTabs"
            :show-arrows="false"
            hide-slider
            @change="$emit('input', $event)"
        >
            <v-list-item>
                <v-tab>
                    <v-icon left>
                        mdi-account
                    </v-icon>
                    Persoon
                </v-tab>
            </v-list-item>
            <v-list-item>
                <v-tab>
                    <v-icon left>
                        mdi-lock
                    </v-icon>
                    Account
                </v-tab>
            </v-list-item>
            <v-list-item>
                <v-tab>
                    <v-icon left>
                        mdi-home
                    </v-icon>
                    Adresgegevens
                </v-tab>
            </v-list-item>
            <v-list-item>
                <v-tab>
                    <v-icon left>
                        $lckv-tent
                    </v-icon>
                    Kamphistorie
                </v-tab>
            </v-list-item>
            <v-list-item>
                <v-tab>
                    <v-icon left>
                        mdi-heart
                    </v-icon>
                    Relaties
                </v-tab>
            </v-list-item>
            <v-list-item>
                <v-tab>
                    <v-icon left>
                        mdi-text
                    </v-icon>
                    Notities
                </v-tab>
            </v-list-item>
        </v-tabs>
    </v-navigation-drawer>
</template>

<style lang="scss" scoped>
    .sidebar {

        &__header {
            width: 100%;
            display: block;
            background-repeat: no-repeat;

            @media screen and (min-width: 769px) {
                background-size: contain;
                background-position: top center;
                background-image: URL('~@/assets/images/person_editor_header.png');
            }
            @media screen and (max-width: 768px) {
                background-size: cover;
                background-position: bottom center;
                background-image: URL('~@/assets/images/person_editor_header_small.png');
            }
        }

        &__avatar {
            width:30%;
            border-width: 4px;
            border-color: var(--background-color);
            border-style: solid;
            display: block;

            @media screen and (min-width: 769px) {
                margin-top: 22%;
                margin-left: auto;
                margin-right: auto;
            }
            @media screen and (max-width: 768px) {
                //
            }

            ::v-deep img {
                width: 100%;
            }
        }
    }

    .header{
        &__content {
            @media screen and (min-width: 769px) {
                display: block;
            }
            @media screen and (max-width: 768px) {
                padding: 2%;
                display: flex;
                align-items: center;
                gap: 20px;
            }
        }
        &__name {
            @media screen and (min-width: 769px) {
                font-size: 18px;
                text-align: center;
                margin-top: 1%;
            }
            @media screen and (min-width: 601px) and (max-width: 768px) {
                font-size: 40px;
                color: #ffffff;
                font-weight: bold;
            }
            @media screen and (max-width: 600px) {
                font-size: 20px;
                color: #ffffff;
                font-weight: bold;
            }
        }
        &__role {
            @media screen and (min-width: 769px) {
                font-size: 14px;
                text-align: center;
                margin-top: 1%;
            }
            @media screen and (min-width: 601px) and (max-width: 768px) {
                font-size: 25px;
                color: #ffffff;
            }
            @media screen and (max-width: 600px) {
                font-size: 15px;
                color: #ffffff;
            }
        }
    }
    .navigation {
        @media screen and (min-width: 769px) {
            margin-top: 20px;
        }

        ::v-deep {
            &:not(.v-tabs--vertical) .v-list-item {
                padding: 0 8px;
            }

            .v-tab {
                width: 100%;
                @media screen and (min-width: 769px) {
                    justify-content: flex-start;
                }
                @media screen and (max-width: 768px) {
                    line-height: 50px;

                    font-size: 0;
                    padding: 0;
                    min-width: 30px;
                    text-align: center;

                    .v-icon {
                        margin: 0;
                    }
                }
            }
        }
    }
</style>
