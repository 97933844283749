import AuthApiService from './AuthApiService';
import KampenApiService from './KampenApiService';
import PersonenApiService from './PersonenApiService';

export const $api = {
    auth:       new AuthApiService(),
    deelnemers: new KampenApiService("deelnemers"),
    kampen:     new KampenApiService(),
    kandidaten: new KampenApiService("kandidaten"),
    personen:   new PersonenApiService(),
    reserves:   new KampenApiService("reserves"),
    stafleden:  new KampenApiService("stafleden"),
};
