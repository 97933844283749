import {defineStore} from "pinia";

export const usePersonStore = defineStore('person', {
    state: () => ({
        id:             null,
        actief:         null,
        geboortedatum:  null,
        voornaam:       null,
        tussenvoegsels: null,
        achternaam:     null,
        geslacht:       null,
        adres:          {},
        telefoon1:      null,
        telefoon2:      null,
        email:          null,
        email_ouder:    null,
        legal_data:     {},
        historie:       {},
    }),
    getters: {
        // doubleCount: (state) => state.count * 2,
    },
    actions: {
        setPerson(person) {
            if (! person) {
                return;
            }
            for (let key of Object.keys(person)) {
                if (this[key] !== undefined) {
                    this[key] = person[key];
                }
            }
        }
    },
});
