import Vue from 'vue';
import Vuex from 'vuex';
import storePlugins from "@/plugins/storePlugins";


Vue.use(Vuex);

export default new Vuex.Store({
    plugins: [storePlugins],
    state: {
        authenticated: false,
    },
    mutations: {
        setAuthentication(state, status) {
            state.authenticated = status;
        }
    }
});
