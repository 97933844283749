<script>

import { required, minLength } from 'vuelidate/lib/validators';
import TwoFactorDialog from './TwoFactorDialog.vue';
import {mapStores} from "pinia";
import {useAccountStore} from "@/stores/AccountStore";

export default {
    name: "PersoonEditAccount",

    components: { TwoFactorDialog },

    validations: {
        'accountStore.gebruikers_naam': { minLength: minLength(6) },
        wachtwoordOud:                  { required },
        wachtwoordNieuw:                { required },
        wachtwoordBevestigen:           { required },
    },

    data() {
        return {
            changePassword:       false,
            twoFactor:            false,
            storedAccount:        {},
            wachtwoordBevestigen: "",
            wachtwoordNieuw:      "",
            wachtwoordOud:        "",
        };
    },

    computed: {
        ...mapStores(useAccountStore),
        twoFactorDialogOpen() {
            return this.twoFactor && ! this.accountStore.uses_2fa;
        }
    },

    mounted() {
        this.twoFactor = this.accountStore.uses_2fa;
    }
};
</script>

<template>
    <div>
        <div class="card-header">
            <div style="display: flex">
                <v-icon>mdi-lock</v-icon>
                <v-card-title>Account</v-card-title>
            </div>
            <v-divider />
        </div>
        <div v-if="$slots.alert" class="alert-container">
            <slot name="alert" />
        </div>
        <div class="edit-container">
            <div>
                <v-text-field
                    v-model="accountStore.gebruikers_naam"
                    label="Gebruikersnaam"
                />
            </div>
            <div>
                <v-switch
                    v-model="twoFactor"
                    label="2FA"
                />
                <two-factor-dialog
                    :dialog="twoFactorDialogOpen"
                    @close="twoFactor = false"
                />
            </div>
            <div v-if="changePassword">
                <v-text-field
                    v-model="wachtwoordOud"
                    label="Wachtwoord (oud)"
                    type="password"
                    :validations="$v.wachtwoordOud"
                />
                <v-text-field
                    v-model="wachtwoordNieuw"
                    label="Wachtwoord (nieuw)"
                    type="password"
                    :validations="$v.wachtwoordNieuw"
                />
                <v-text-field
                    v-model="wachtwoordBevestigen"
                    label="Wachtwoord (bevestigen)"
                    type="password"
                    :validations="$v.wachtwoordBevestigen"
                />
                <div>
                    <a class="change-password" @click="changePassword = ! changePassword">Annuleren</a>
                </div>
            </div>
            <div v-else>
                <a class="change-password" @click="changePassword = ! changePassword"> Wachtwoord wijzigen</a>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .edit-container {
        //@media screen and (min-width: 769px) {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        gap: 25px 50px;

        > div {
            width: calc(50% - 25px);
        }
        //}
    }
    .card-header {
        height: 79px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 12px;
    }
    .change-password {
        cursor: pointer;
    }
</style>
