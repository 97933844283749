<script>

import {useNotificationStore} from "@/stores/NotificationStore";
import {mapStores} from "pinia";

export default {
    name: 'App',

    data: () => ({
        showNavigation: true,
        showSidepanel: false,
        theme: 'theme-dark'
    }),

    computed: {
        ...mapStores(useNotificationStore)
    }
};

</script>

<template>
    <v-app>
        <router-view />
        <v-snackbar
            v-if="notificationStore"
            v-model="notificationStore.active"
            v-bind="notificationStore.params"
            :color="notificationStore.color"
            @input="notificationStore.handleClose"
        >
            {{ notificationStore.message }}

            <template #action="{ attrs }">
                <v-btn
                    v-if="notificationStore.button"
                    text
                    v-bind="attrs"
                    @click="notificationStore.button.callback"
                >
                    {{ notificationStore.button.text }}
                </v-btn>
            </template>
        </v-snackbar>
    </v-app>
</template>

<style lang="scss">
    @import "variables";
    @import "helpers";

    html, body {
        height: 100%;
    }

    .theme--light {
        .v-tabs>.v-tabs-bar .v-tab--active>.v-icon {
            fill: rgba(0, 0, 0, 0.87);
        }
        .v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active)>.v-icon {
            fill: rgba(0, 0, 0, .54)
        }
        .v-icon {
            fill: rgb(255,255,255);
        }
    }
    .v-list-item--active .theme--light.v-icon {
        fill: #2196f3 !important;
    }

    .v-application {
        .theme--dark {
            .v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active)>.v-icon {
                fill: hsla(0,0%,100%,.6);
            }
            .v-icon {
                fill: #ffffff;
            }
            .primary--text {
                color: #ffffff !important;
            }
        }
    }

</style>
