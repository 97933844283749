<script>
import StraMultipleTabTable from '../../../components/StraMultipleTabTable.vue';
import {mapStores} from "pinia";
import {usePersonStore} from "@/stores/PersonStore";
export default {
    name: "PersoonEditHistorie",

    components: {StraMultipleTabTable},

    data() {
        return {
            historie: {
                "deelnemer": {
                    clickAction: (item) => { console.log(item); },
                    title: "Deelnemer",
                    columns: [
                        { value: 'jaar', text: 'Jaar '},
                        { value: 'terrein', text: 'Terrein '},
                        { value: 'nummer', text: 'Nummer '},
                    ],
                    data: [
                        { jaar: '2017', terrein: 'Diever', nummer: 7 },
                        { jaar: '2016', terrein: 'Wiltz', nummer: 26 },
                        { jaar: '2015', terrein: 'Terschelling', nummer: 14 },
                        { jaar: '2014', terrein: 'Diever', nummer: 2 },
                    ]
                },
                "staf": {
                    clickAction: (item) => { console.log(item); },
                    title: "Staf",
                    columns: [
                        { value: 'vertrek', text: 'Jaar', formatValue: (item) => this.$moment(item).format("YYYY") },
                        { value: 'terrein', text: 'Terrein', formatValue: (item) =>  item.naam || "<leeg>" },
                        { value: 'nummer', text: 'Nummer '},
                        { value: 'functie', text: 'Functie '},
                    ],
                    data: [],
                }
            }
        };
    },

    computed: {
        ...mapStores(usePersonStore),
    },

    mounted() {
        for (let tab of Object.keys(this.historie)) {
            if (this.personStore.historie[tab]) {
                this.historie[tab].data = this.personStore.historie[tab];
            } else {
                delete this.historie[tab];
            }
        }
    }
};
</script>

<template>
    <div>
        <div class="card-header">
            <div style="display: flex">
                <v-icon>$lckv-tent</v-icon>
                <v-card-title>Kamphistorie</v-card-title>
            </div>
            <v-divider />
        </div>
        <div v-if="$slots.alert" class="alert-container">
            <slot name="alert" />
        </div>
        <stra-multiple-tab-table
            class="kamp-overview__table"
            :compact="false"
            :value="historie"
        />
    </div>
</template>

<style lang="scss">
    .card-header {
        height: 79px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 12px;
    }
</style>
