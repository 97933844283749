<script>

import {usePersonStore} from "@/stores/PersonStore";
import {mapStores} from "pinia";

export default {
    name: 'PersoonEditAdres',

    computed: {
        ...mapStores(usePersonStore)
    },

    methods: {
        handlePostcode() {
            let regex = new RegExp(/^([0-9]{4})([a-zA-Z]{2})$/);
            if (regex.test(this.personStore.adres.postcode.trim())) {
                let postcode = this.personStore.adres.postcode.trim().match(regex);
                this.personStore.adres.postcode = `${postcode[1]} ${postcode[2].toUpperCase()}`;
            }
        }
    }
};
</script>

<template>
    <div>
        <div class="card-header">
            <div style="display: flex">
                <v-icon>mdi-home</v-icon>
                <v-card-title>Adresgegevens</v-card-title>
            </div>
            <v-divider />
        </div>
        <div v-if="$slots.alert" class="alert-container">
            <slot name="alert" />
        </div>
        <div class="edit-container">
            <div>
                <div style="display: flex; gap: 10px;">
                    <v-text-field
                        v-model="personStore.adres.straat"
                        label="Straat"
                        style="width: 80%"
                    />
                    <v-text-field
                        v-model="personStore.adres.nummer"
                        label="Nr"
                        @input="$emit('update', persoon)"
                    />
                </div>
                <v-text-field
                    v-model="personStore.adres.postcode"
                    label="Postcode"
                    @input="handlePostcode"
                />
                <v-text-field
                    v-model="personStore.adres.plaats"
                    label="Woonplaats"
                    @input="$emit('update', persoon)"
                />
                <v-text-field
                    v-model="personStore.adres.land"
                    label="Land"
                    @input="$emit('update', persoon)"
                />
            </div>

            <div>
                <v-text-field
                    v-model="personStore.telefoon1"
                    label="Telefoon 1"
                    @input="$emit('update', persoon)"
                />
                <v-text-field
                    v-model="personStore.telefoon2"
                    label="Telefoon 2"
                    @input="$emit('update', persoon)"
                />
                <v-text-field
                    v-model="personStore.email"
                    label="E-mail"
                    @input="$emit('update', persoon)"
                />
                <v-text-field
                    v-model="personStore.email_ouder"
                    label="E-mail ouders"
                    @input="$emit('update', persoon)"
                />
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .edit-container {
        @media screen and (min-width: 769px) {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            gap: 25px 50px;

            > div {
                width: calc(50% - 25px);
            }
        }
    }
    .card-header {
        height: 79px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 12px;
    }
</style>
