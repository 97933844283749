import { ModelApiService } from "./BaseApiService";

export default class PersonenApiService extends ModelApiService {
    /**
     * UserApiService Constructor
     */
    constructor() {
        super("persoon");
    }
}
