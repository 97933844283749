<script>
export default {
    name: "PersoonEditRelaties",

};
</script>

<template>
    <div>
        TODO RELATIES
    </div>
</template>

<style lang="scss" scoped>

</style>
