import Vue from 'vue';
import VueRouter from 'vue-router';
import StraRoutes from './StraRoutes';

import LoginLayout from '@/components/login/LoginLayout';
import LoginForm from '@/components/login/LoginForm';
import ForgotPassword from '@/components/login/ForgotPassword';
import ResetPassword from '@/components/login/ResetPassword';

Vue.use(VueRouter);

let routes = [
    {
        path:      '/authorization',
        component: LoginLayout,
        children:  [
            {
                path:      '/',
                component: LoginForm,
            },
            {
                path:      'vergeten',
                component: ForgotPassword,
            },
            {
                path:      'reset',
                component: ResetPassword,
            }
        ]
    },
];

routes = routes.concat(StraRoutes);

export default new VueRouter({
    routes,
    mode: 'history'
});
