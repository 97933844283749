import store from '../store';
import axios from 'axios';
import { BaseApiService } from './BaseApiService';

export default class AuthApiService extends BaseApiService {
    /**
     * UserApiService Constructor
     */
    constructor() {
        super("auth");
    }

    /**
     * @param {object} data
     * @returns {void}
     */
    async login(data) {
        let response = await axios.post('auth/login', data);
        if (response.data.error) {
            throw response.data.errors;
        }

        if (response.data.data.access_token) {
            localStorage.setItem('token', response.data.data.access_token);
            this.updateAuthorizationHeader();
            store.commit('setAuthentication', true);
        }
        return response.data.data;
    }

    /**
     * Returns current user
     *
     * @returns {object}
     */
    async me() {
        try {
            let response = await axios.post('auth/me');
            if (response.data.error) {
                throw response.data.errors;
            }
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    /**
     * Returns current user
     *
     * @param {object} data
     * @returns {boolean}
     */
    async forgot(data) {
        try {
            let response = await axios.post('auth/password/request', data);
            if (response.data.error) {
                throw response;
            }
            return true;
        } catch (err) {
            this.handleErrors(err);
            return false;
        }
    }

    /**
     * Returns current user
     *
     * @param {object} data
     * @returns {boolean}
     */
    async reset(data) {
        try {
            let response = await axios.post('auth/password/reset', data);
            if (response.data.error) {
                throw response.data.errors;
            }
            return true;
        } catch (err) {
            this.handleErrors(err);
            return false;
        }
    }

    /**
     * @returns {object}
     */
    async  setTwoFactorAuthentication() {
        try {
            let response = await axios.get('auth/twoFactor');
            if (response.data.error) {
                throw response.data.errors;
            }
            return response.data;
        } catch (err) {
            this.handleErrors(err);
            return err;
        }
    }

    /**
     * @returns {object}
     */
    async  deleteTwoFactorAuthentication() {
        try {
            let response = await axios.delete('auth/twoFactor');
            if (response.data.error) {
                throw response.data.errors;
            }
            return response.data;
        } catch (err) {
            this.handleErrors(err);
            return err;
        }
    }

    /**
     * @param {string} token
     * @param {string} code
     * @returns {object}
     */
    async  validateTwoFactorAuthentication(token, code) {
        let response = await axios.post(`auth/validateCode/${token}/${code}`);
        if (response.data.error) {
            throw response.data.errors;
        }

        if (response.data.data.access_token) {
            localStorage.setItem('token', response.data.data.access_token);
            this.updateAuthorizationHeader();
            store.commit('setAuthentication', true);
        }
        return true;
    }
}
