<script>
import StraMultipleTabTable from '../../components/StraMultipleTabTable';

export default {
    name: 'KampOverview',

    components: { StraMultipleTabTable },

    props: {
        value: {
            type: Object,
            default: null,
        }
    },

    data() {
        return {

            kampInfo: this.clone(this.value),

            // Table information
            deelnemers: [],
            kandidaten: [],
            reserves:   [],
            stafleden:  [],

            // other
            dense: false,
            loading: ! this.value,
        };
    },

    computed: {
        tableData() {
            let result = {};
            const personColumns = [
                { "value": "voornaam",      "text": "Voornaam" },
                { "value": "tussenvoegsel", "text": "Tussenvoegsel" },
                { "value": "achternaam",    "text": "Achternaam" },
                { "value": "geslacht",      "text": "Geslacht" },
                { "value": "email",         "text": "E-mail" },
                { "value": "telefoon",      "text": "Telefoon" }
            ];

            if (this.deelnemers) {
                // parse and stringify to prevent personColumns reference
                let deelnemerColumns = this.clone(personColumns);
                deelnemerColumns.push({ "value": "groen", "text": "Groen", "type": "boolean", formatValue: (value) => { return value === true ? "G" : "O"; } });

                result['deelnemers'] = {
                    "title":       `Deelnemers`,
                    "clickAction": (item) => { this.$router.push(`/persoon/${item.id}`); },
                    "columns":     deelnemerColumns,
                    "data":        this.deelnemers,
                };
            }

            if (this.kandidaten) {
                // parse and stringify to prevent personColumns reference
                let kandidatenColumns = this.clone(personColumns);

                result['kandidaten'] = {
                    "title":   "Kandidaten",
                    "columns": kandidatenColumns,
                    "data":    this.kandidaten
                };
            }

            if (this.reserves) {
                // parse and stringify to prevent personColumns reference
                let reservesColumns = this.clone(personColumns);

                result['reserves'] = {
                    "title":   "Reserves",
                    "columns": reservesColumns,
                    "data":    this.reserves
                };
            }

            if (this.stafleden) {
                // parse and stringify to prevent personColumns reference
                let stafColumns = this.clone(personColumns);
                stafColumns.push({ "value": "functie_afkorting", "text": "Functie" });

                result['stafleden'] = {
                    "title":   "Stafleden",
                    "columns": stafColumns,
                    "data":    this.stafleden
                };
            }
            return result;
        }
    },

    watch: {
        $route(newValue) {
            if (newValue.params && newValue.params.value) {
                this.kampInfo = newValue.params.value;
            } else {
                this.loading = true;
            }
            this.initOverview();
        }
    },

    mounted() {
        this.initOverview();
    },

    methods: {
        async initOverview() {

            this.deelnemers = [];
            this.kandidaten = [];
            this.reserves   = [];
            this.stafleden  = [];

            // no need to await this function because we already have some kamp data to show. We just want to check if the info isn't old
            this.getKampData();
            await Promise.all([
                this.getDeelnemers(),
                this.getKandidaten(),
                this.getReserves(),
                this.getStafleden()
            ]);

        },

        async getDeelnemers() {
            let response = await this.$api.deelnemers.get(1);
            if (! response.error) {
                this.deelnemers = response.data;
            }
        },

        async getKandidaten() {
            let response = await this.$api.kandidaten.get(1);
            if (! response.error) {
                this.kandidaten = response.data;
            }
        },

        async getReserves() {
            let response = await this.$api.reserves.get(1);
            if (! response.error) {
                this.reserves = response.data;
            }
        },

        async getStafleden() {
            let response = await this.$api.stafleden.get(1);
            if (! response.error) {
                this.stafleden = response.data;
            }
        },

        async getKampData() {
            try {
                let response  = await this.$api.kampen.get(this.$route.params.id);
                this.kampInfo = response.data;
            } catch(err) {
                // Do nothing, errors are handled by axios interceptor
            } finally {
                this.loading  = false;
            }
        },

        clone(data) {
            return JSON.parse(JSON.stringify(data));
        }
    }
};
</script>

<template>
    <div class="kamp-overview__wrapper">
        <div v-if="! loading">
            <div v-if="kampInfo">
                <div class="kamp-overview__header">
                    <div class="page-header__wrapper">
                        <div class="page-header__titles">
                            <h2 class="kamp-overview__main-title">
                                Kamp {{ kampInfo.nummer }} {{ kampInfo.terrein.naam }}
                            </h2>
                            <h3 class="kamp-overview__sub-title">
                                Week {{ kampInfo.week_nummer }}: {{ kampInfo.vertrek | moment('D MMMM YYYY') }} - {{ kampInfo.aankomst | moment('D MMMM YYYY') }}
                            </h3>
                        </div>
                        <div class="page-header__actions">
                            <v-btn icon elevation="2" @click="dense = ! dense">
                                <v-icon v-if="! dense">
                                    mdi-arrow-collapse-vertical
                                </v-icon>
                                <v-icon v-else>
                                    mdi-arrow-expand-vertical
                                </v-icon>
                            </v-btn>
                        </div>
                    </div>
                </div>

                <stra-multiple-tab-table
                    class="kamp-overview__table"
                    :dense="dense"
                    :value="tableData"
                />
            </div>
        </div>
        <div v-else class="stra-full-page-spinner">
            <v-progress-circular indeterminate color="primary" />
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .kamp-overview {
        &__wrapper {
            display: flex;
            flex-direction: column;
            height: 100%;
        }

        &__header {
            transition: .3s cubic-bezier(.4,0,.2,1);
            background: var(--background-color);
            margin: -16px;
            padding: 16px;
        }

        &__main-title {
            font-weight: bold;
            font-size: 20px;
            margin: 10px 0;
        }

        &__sub-title {
            font-weight: normal;
            font-size: 16px;
            margin: 5px 0;
        }

        &__table {
            margin: 30px 0 0 0;
            flex-grow: 1;
        }
    }

    .page-header {
        &__wrapper {
            display: flex;
            direction: row;
        }
        &__titles {
            flex-grow: 1;
        }
    }
</style>
