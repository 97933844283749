<script>
import QrcodeVue from 'qrcode.vue';

export default {
    name: "TwoFactorDialog",

    components: { QrcodeVue },

    props: {
        dialog: { type: Boolean, default: false },
    },

    data() {
        return { loading: true, qrUrl: null,};
    },

    // mounted() {
    //     this.getTFACode();
    // },

    watch: {
        dialog(newValue) {
            console.log(newValue);
            if (newValue) {
                this.getTFACode();
            }
        }
    },

    methods: {
        async getTFACode() {
            this.loading = true;
            let response = await this.$api.auth.setTwoFactorAuthentication();
            this.qrUrl   = response.data.qr_url;
            this.loading = false;
        },

        async save() {
            this.loading = true;
            // await this.$api.auth.validateTwoFactorAuthentication(this.code);
            setTimeout(() => {
                this.$emit('close');

                setTimeout(() => {
                    this.loading = false;
                }, 500);
            }, 2500);
        },

        // async cancel() {
        //     this.loading = true;
        //     await this.$api.auth.deleteTwoFactorAuthentication(this.code);
        //     this.$emit('cancel');
        //     this.close();
        // }
    }
};
</script>

<template>
    <v-dialog
        :value="dialog"
        max-width="600"
        persistent
    >
        <v-card>
            <v-toolbar
                color="primary"
                dark
            >
                Tweestapsverificatie inschakelen
            </v-toolbar>
            <div v-if="! loading">
                <v-card-text>
                    <ol class="two-factor__list">
                        <li>Installeer een authenticator app op je smartphone, bijvoorbeeld "Google authenticator"</li>
                        <li>Scan onderstaande QR code met de authenticator app.</li>

                        <div v-if="qrUrl" class="two-factor__qr-wrapper">
                            <qrcode-vue
                                class="two-factor__qr"
                                :value="qrUrl"
                                size="150"
                            />
                        </div>
                    </ol>
                </v-card-text>

                <v-divider />

                <v-card-actions>
                    <v-spacer />
                    <v-btn plain @click="$emit('close')">
                        Annuleren
                    </v-btn>
                    <v-btn color="primary" @click="save">
                        Opslaan
                    </v-btn>
                </v-card-actions>
            </div>

            <div v-else class="stra-full-page-spinner">
                <v-progress-circular indeterminate color="primary" />
            </div>
        </v-card>
    </v-dialog>
</template>

<style lang="scss" scoped>

    .v-toolbar {
        font-size: 1.5em;
    }

    .two-factor {
        &__qr-wrapper  {
            text-align: center;
            margin-bottom: 20px;
            margin-left: -40px;
        }
        &__qr {
            display: inline-block;
            background: #ffffff;
            padding: 15px;
            border-radius: 10px;
            border: 1px solid #ddd;
            box-shadow: 0 0 10px 5px rgb(0 0 0 / 8%);
        }

        &__list li {
            margin-bottom: 20px;
        }

        &__input {
            width: 150px;
        }
    }
</style>
